<template>
  <section class="pa-3">
    <modal-loading :is-loading="bulkLoading" :message="loadingMessage" />
    <modal-confirmation
      :state-modal="isModal"
      :title="modalProps.title"
      :color="modalProps.color"
      :label="modalProps.label"
      @hideModal="closeModal"
      @apply="bulkAction"
    />
    <div class="d-flex align-start justify-space-between flex-wrap mb-10">
      <div class="d-flex">
        <h2 class="mb-6 mr-8">写真一覧</h2>
        <div class="d-flex">
          <v-btn
            large
            dark
            rounded
            color="#2196F3"
            :to="{ name: 'photos_create' }"
            class="mr-5"
          >
            <v-icon class="mr-3"> mdi-image-outline </v-icon>
            新規登録
          </v-btn>
          <v-btn
            large
            rounded
            outlined
            color="primary"
            :to="{ name: 'photos_bulk_upload' }"
          >
            <v-icon class="mr-3"> mdi-upload </v-icon>
            一括アップロード
          </v-btn>
        </div>
      </div>
      <div>
        <v-radio-group v-model="thumbnailWidth" label="画像サイズ" row dense>
          <v-radio
            v-for="(item, i) in thumbnailSizeList"
            :key="`thumbnail-size-${i}`"
            :label="item.label"
            :value="item.value"
          />
        </v-radio-group>
      </div>
    </div>

    <section>
      <h2 v-if="$route.query['categories[]']" class="mb-4">
        カテゴリ別一覧：{{ category_name }}
      </h2>
      <div class="mb-4">
        全 {{ countAll }} 枚｜公開済み {{ countPublished }} 枚
      </div>
      <div class="d-flex justify-space-between flex-wrap mx-n3">
        <div class="d-flex col-12 col-lg-4">
          <v-select
            v-model="selectedAction"
            class="pr-2"
            style="max-width: 150px"
            :items="actions"
            label="一括操作"
            outlined
            dense
            clearable
          />
          <v-btn
            color="grey lighten-3"
            height="40px"
            :disabled="!(selectedAction && checkedList.length !== 0)"
            @click="openModal"
          >
            実行
          </v-btn>
        </div>

        <div class="d-flex flex-wrap col-12 col-lg-8">
          <v-combobox
            v-model="selectedTags"
            class="pr-4"
            :items="tags"
            item-text="label"
            label="タグ検索"
            multiple
            chips
            dense
          />
          <v-text-field
            :value="keyword"
            type="search"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            height="44px"
            @change="(value) => (keyword = value)"
          />
        </div>
      </div>

      <v-data-table
        v-model="checkedList"
        class="mb-10"
        :headers="headers"
        :items="photos"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :loading-text="'ロード中...'"
        :no-data-text="'データがありません。'"
        hide-default-footer
        show-select
      >
        <template v-slot:header.data-table-select="{ on, props }">
          <v-simple-checkbox
            color="primary"
            v-bind="props"
            v-on="on"
            @input="toggleAll"
          />
        </template>
        <template v-slot:item.data-table-select="{ isSelected, select }">
          <v-simple-checkbox
            color="primary"
            :value="isSelected"
            @input="select($event)"
          />
        </template>
        <template v-slot:item.image="{ item }">
          <div
            class="thumbnailContainer"
            :style="{ width: `${thumbnailWidth}px` }"
          >
            <img :src="item.image" :alt="item.title" />
          </div>
        </template>
        <template v-slot:item.title="{ item }">
          <router-link
            :to="{
              name: 'photos_edit',
              params: { photoId: item.id, from: params },
            }"
          >
            {{ item.title }}
          </router-link>
        </template>
        <template v-slot:item.view_count="{ item }">
          {{ item.view_count }}
        </template>
        <template v-slot:item.place="{ item }">
          {{ formatPlace(item.place) }}
        </template>
        <template v-slot:item.shot_at="{ item }">
          {{ formatShotAt(item.shot_at) }}
        </template>
        <template v-slot:item.user="{ item }">
          {{ item.user.name }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ formatStatus(item.status) }}
        </template>
      </v-data-table>

      <v-pagination
        v-if="photos.length !== 0"
        v-model="options.page"
        :length="lastPage"
        :total-visible="10"
      />
    </section>
  </section>
</template>

<script>
import { DateTime } from "luxon";
import { getData } from "../../axios";

import ModalConfirmation from "../../components/ModalConfirmation.vue";
import ModalLoading from "../../components/ModalLoading.vue";

export default {
  name: "Index",

  components: {
    ModalConfirmation,
    ModalLoading,
  },

  data() {
    return {
      params: {
        page: 1,
        words: "",
        id: "",
        shot_at: "",
        tags: [],
        categories: [],
      },
      category_name: "",
      tags: [],
      selectedTags: [],
      keyword: "",
      loading: false,
      headers: [
        { text: "ID", align: "left", sortable: true, value: "id" },
        { text: "画像", align: "center", sortable: false, value: "image" },
        { text: "タイトル", align: "left", sortable: false, value: "title" },
        {
          text: "ビュー数",
          align: "right",
          sortable: false,
          value: "view_count",
        },
        { text: "撮影場所", align: "left", sortable: false, value: "place" },
        { text: "撮影日時", align: "left", sortable: true, value: "shot_at" },
        { text: "登録者", align: "left", sortable: false, value: "user" },
        { text: "ステータス", align: "left", sortable: false, value: "status" },
      ],
      options: {
        page: 1,
        sortBy: [],
        sortDesc: [],
        itemsPerPage: 10,
      },
      actions: [
        { text: "下書き", value: "unpublished" },
        { text: "公開", value: "published" },
        { text: "削除", value: "delete" },
      ],
      selectedAction: "",
      isModal: false,
      modalProps: {
        title: "",
        color: "",
        label: "",
      },
      bulkLoading: false,
      total: 0,
      lastPage: 0,
      photos: [],
      checkedList: [],
      countAll: 0,
      countPublished: 0,
      thumbnailWidth: 80,
      thumbnailSizeList: [
        {
          label: "小",
          value: 80,
        },
        {
          label: "中",
          value: 140,
        },
        {
          label: "大",
          value: 220,
        },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.query["categories[]"]) {
      getData(`categories/${to.query["categories[]"]}`).then((res) => {
        next((vm) => {
          vm.category_name = res.name;
        });
      });
    } else {
      next();
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query["categories[]"]) {
      getData(`categories/${to.query["categories[]"]}`).then((res) => {
        this.category_name = res.name;
      });
    }
    next();
  },

  computed: {
    client() {
      return this.$store.getters["auth/client"].setting;
    },
    loadingMessage() {
      const message = (() => {
        if (this.selectedAction === "unpublished") {
          return `${this.checkedList.length}件の写真を下書きに編集しています...`;
        } else if (this.selectedAction === "published") {
          return `${this.checkedList.length}件の写真を公開しています...`;
        } else if (this.selectedAction === "delete") {
          return `${this.checkedList.length}件の写真を削除しています...`;
        }
      })();
      return message;
    },
  },

  watch: {
    keyword() {
      if (
        this.params.words !== this.$route.query.words ||
        this.params.words !== this.keyword ||
        this.$route.query.words !== this.keyword
      ) {
        this.search();
      }
    },
    $route(newValue, oldValue) {
      if (this.$route.fullPath === "/photos") {
        this.params.page = 1;
        this.params.id = "";
        this.params.shot_at = "";
        this.params.words = "";
        this.params.tags = [];
        this.params.categories = [];
        this.selectedTags = [];
        this.keyword = "";
        this.options.page = 1;
        this.options.sortBy = [];
        this.options.sortDesc = [];
        this.getPhotos();
        this.routerPush();
      }
      if (newValue.query["tags[]"] !== oldValue.query["tags[]"]) {
        this.params.tags = newValue.query["tags[]"];
        this.getPhotos();
      }
      if (newValue.query["categories[]"] !== oldValue.query["categories[]"]) {
        this.params.categories = newValue.query["categories[]"];
        this.getPhotos();
      }
      if (newValue.query.page !== oldValue.query.page) {
        this.params.page = newValue.query.page;
        this.getPhotos();
      }
    },
    // optionsではpageの更新が監視できない時がある為
    "options.page"() {
      if (Number(this.params.page) !== Number(this.options.page)) {
        this.params.page = Number(this.options.page);
        this.checkedList = [];
        this.getPhotos();
        this.routerPush();
      }
    },
    options(newValue, oldValue) {
      if (
        newValue.sortBy.length !== oldValue.sortBy.length ||
        newValue.sortBy[0] !== oldValue.sortBy[0] ||
        newValue.sortDesc[0] !== oldValue.sortDesc[0]
      ) {
        if (this.options.sortBy.length !== 0) {
          const key = this.options.sortBy[0];
          const order = this.options.sortDesc[0] ? "desc" : "asc";
          if (key === "id") {
            this.params.shot_at = "";
          } else if (key === "shot_at") {
            this.params.id = "";
          }
          this.params[key] = order;
          if (
            this.paramsQueryTagsDifferent() &&
            this.paramsQueryCategoriesDifferent()
          ) {
            this.getPhotos();
            this.routerPush();
          }
        } else {
          this.setQueryToParams();
          this.params.id = "";
          this.params.shot_at = "";
          if (
            this.paramsQueryTagsDifferent() &&
            this.paramsQueryCategoriesDifferent()
          ) {
            this.getPhotos();
            this.routerPush();
          }
        }
      }
    },
    selectedTags(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.params.page = 1;
        this.options.page = 1;
      }
      if (this.selectedTags.length === 0) {
        this.params.tags = [];
        if (this.paramsQueryTagsDifferent()) {
          this.getPhotos();
          this.routerPush();
        }
      } else {
        this.params.tags = this.selectedTags.map((tag) => tag.id);
        if (this.paramsQueryTagsDifferent()) {
          this.getPhotos();
          this.routerPush();
        }
      }
    },
  },

  created() {
    getData("tags")
      .then((res) => {
        this.tags = res.data;
      })
      .finally(() => {
        this.setQueryToOptions();
        this.setQueryToParams();
        if (this.client) {
          this.getPhotos();
        }
      });
  },

  methods: {
    routerPush() {
      const params = Object.assign({}, this.params);
      Object.keys(params).forEach((key) => {
        // 空文字のクエリーを無くす：例）words='', tags=[]
        if (
          params[key] === "" ||
          (Array.isArray(params[key]) && params[key].length === 0)
        ) {
          delete params[key];
        }
        // クエリー用のKeyに変える
        if (key === "tags" && Array.isArray(params.tags)) {
          params["tags[]"] = params["tags"].slice();
          delete params["tags"];
        }
        if (key === "categories" && Array.isArray(params.categories)) {
          params["categories[]"] = params["categories"].slice();
          delete params["categories"];
        }
      });
      window.document.documentElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.$router.push({ query: params });
    },
    setQueryToParams() {
      const queries = Object.assign({}, this.$route.query);
      if (queries.words) {
        this.keyword = queries.words;
      }
      if (queries["tags[]"]) {
        this.tags.forEach((tag) => {
          // 選択済みのタグの重複を防ぐ
          const exist = Object.keys(this.selectedTags).find(
            (key) => Number(this.selectedTags[key].id) === Number(tag.id)
          );
          if (Array.isArray(queries["tags[]"])) {
            queries["tags[]"].forEach((id) => {
              if (Number(tag.id) === Number(id) && !exist) {
                this.selectedTags.push(tag);
              }
            });
          } else if (Number(tag.id) === Number(queries["tags[]"]) && !exist) {
            this.selectedTags.push(tag);
          }
        });
      }
      Object.keys(queries).forEach((key) => {
        if (key === "tags[]" || key === "categories[]") {
          if (key === "tags[]") {
            // params['tags']のタグの重複を防ぐ
            const exist =
              this.params.tags.length !== 0 &&
              this.params.tags.find(
                (id) => Number(id) === Number(queries[key])
              );
            if (!exist) {
              if (Array.isArray(queries[key])) {
                queries[key].forEach((id) => this.params.tags.push(id));
              } else {
                this.params.tags.push(queries[key]);
              }
            }
          }
          if (key === "categories[]") {
            // params['categories']のタグの重複を防ぐ
            const exist =
              this.params.categories.length !== 0 &&
              this.params.categories.find(
                (id) => Number(id) === Number(queries[key])
              );
            if (!exist) {
              if (Array.isArray(queries[key])) {
                queries[key].forEach((id) => this.params.categories.push(id));
              } else {
                this.params.categories.push(queries[key]);
              }
            }
          }
        } else {
          this.params[key] = queries[key];
        }
      });
    },
    setQueryToOptions() {
      const queries = Object.assign({}, this.$route.query);
      Object.keys(queries).forEach((key) => {
        if (key === "id" || key === "shot_at") {
          this.options.sortBy[0] = key;
          this.options.sortDesc[0] = queries[key] === "desc";
        } else if (key === "page") {
          this.options.page = Number(queries[key]);
        }
      });
    },
    paramsQueryTagsDifferent() {
      const queryTags = Array.isArray(this.$route.query["tags[]"])
        ? this.$route.query["tags[]"].slice().map(Number)
        : this.$route.query["tags[]"]
        ? [this.$route.query["tags[]"]].map(Number)
        : [];

      const paramsTags = Array.isArray(this.params.tags)
        ? this.params.tags.slice().map(Number)
        : [];

      const tagsIsDiff = queryTags
        .concat(paramsTags)
        .filter((id) => !queryTags.includes(id) || !paramsTags.includes(id));

      // paramsとqueryで違う項目がある場合はtrue
      // query.xxxがundefinedの場合は空文字で比較
      return Boolean(
        Number(this.params.page) !== Number(this.$route.query.page) ||
          this.params.words !== (this.$route.query.words || "") ||
          this.params.id !== (this.$route.query.id || "") ||
          this.params.shot_at !== (this.$route.query.shot_at || "") ||
          tagsIsDiff.length !== 0
      );
    },
    paramsQueryCategoriesDifferent() {
      const queryCategories = Array.isArray(this.$route.query["categories[]"])
        ? this.$route.query["categories[]"].slice().map(Number)
        : this.$route.query["categories[]"]
        ? [this.$route.query["categories[]"]].map(Number)
        : [];

      const paramsCategories = Array.isArray(this.params.categories)
        ? this.params.categories.slice().map(Number)
        : [];

      const categoriesIsDiff = queryCategories
        .concat(paramsCategories)
        .filter(
          (id) =>
            !queryCategories.includes(id) || !paramsCategories.includes(id)
        );

      // paramsとqueryで違う項目がある場合はtrue
      // query.xxxがundefinedの場合は空文字で比較
      return Boolean(
        Number(this.params.page) !== Number(this.$route.query.page) ||
          this.params.words !== (this.$route.query.words || "") ||
          this.params.id !== (this.$route.query.id || "") ||
          this.params.shot_at !== (this.$route.query.shot_at || "") ||
          categoriesIsDiff.length !== 0
      );
    },
    getPhotosCounts() {
      const params = this.$route.query["categories[]"]
        ? { "categories[]": this.$route.query["categories[]"] }
        : null;
      getData("photos/counts", params)
        .then((res) => {
          this.countAll = res.data.countAll;
          this.countPublished = res.data.countPublished;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getPhotos() {
      this.loading = true;
      const params = Object.assign({}, this.params);
      if (this.client) {
        this.getPhotosCounts();
        params["per_page"] = this.client.perPage;
        getData("photos", params)
          .then((res) => {
            this.photos = res.data;
            this.total = res.total;
            this.lastPage = res.last_page;
            this.loading = false;
            if (this.bulkLoading) {
              this.bulkLoading = false;
              this.setSuccessMessage();
            }
          })
          .catch(() => {
            this.loading = false;
            this.bulkLoading = false;
          });
      }
    },
    search() {
      this.params.words = this.keyword;
      // 検索後は1ページを表示し、ソートもリセット
      this.params.page = 1;
      this.params.id = "";
      this.params.shot_at = "";
      this.options.page = 1;
      this.options.sortBy = [];
      this.options.sortDesc = [];
      this.getPhotos();
      // query.wordsとkeywordのどちらかが存在し、異なるときはURLを書き換える
      if (
        (this.$route.query.words || this.keyword) &&
        this.$route.query.words !== this.keyword
      ) {
        this.routerPush();
      }
    },
    onCheckboxChange(target) {
      if (target.checked) {
        this.checkedList.push(target.id);
      } else {
        this.checkedList = this.checkedList.filter((v) => v !== target.id);
      }
    },
    bulkAction() {
      this.isModal = false;
      this.bulkLoading = true;
      const isMatchBulkAction = ["unpublished", "published", "delete"].some(
        (bulkAction) => this.selectedAction === bulkAction
      );
      if (!isMatchBulkAction) {
        return;
      }

      const bulkList = {
        type:
          this.selectedAction === "unpublished" ? "draft" : this.selectedAction,
        ids: this.checkedList.map((photo) => photo.id),
      };

      fetch(`${process.env.VUE_APP_API_URL}/api/admin/photos/bulk`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
        },
        body: JSON.stringify(bulkList),
      }).catch((e) => {
        this.bulkLoading = false;
        throw e;
      }).then(() => {
        this.bulkLoading = false;
        this.checkedList = [];
        this.getPhotos();
      });
    },
    handleFormData(photoData) {
      const formData = new FormData();
      Object.keys(photoData).forEach((key) => {
        if (
          photoData[key] === "" ||
          photoData[key] === null ||
          photoData[key] === "null"
        ) {
          return;
        }
        if (key === "image") {
          // 'string'となっておりAPIのバリデーションに引っかかる為formDataに入れない
          return;
        } else if (key === "status") {
          formData.append(key, this.selectedAction);
        } else if (key === "tags" && photoData.tags && photoData[key]) {
          photoData[key].forEach((tag) => {
            formData.append(key + "[]", Number(tag.id));
          });
        } else {
          formData.append(key, photoData[key]);
        }
      });
      return formData;
    },
    setSuccessMessage() {
      const message = (() => {
        if (this.selectedAction === "unpublished") {
          return `${this.checkedList.length}件の写真を下書きに編集しました。`;
        } else if (this.selectedAction === "published") {
          return `${this.checkedList.length}件の写真を公開しました。`;
        } else if (this.selectedAction === "delete") {
          const count = this.checkedList.length;
          this.checkedList = [];
          return `${count}件の写真を削除しました。`;
        }
      })();
      this.$store.dispatch("snackbar/setSnackbar", {
        message,
        color: "success",
        timeout: 2000,
      });
    },
    toggleAll() {
      if (this.checkedList.length !== 0) {
        this.checkedList = [];
      } else {
        this.checkedList = this.photos.slice();
      }
    },
    openModal() {
      this.modalProps = (() => {
        if (this.selectedAction === "unpublished") {
          return {
            title: `${this.checkedList.length}件の写真を下書きにしてもよろしいですか？`,
            color: "primary",
            label: "下書きにする",
          };
        } else if (this.selectedAction === "published") {
          return {
            title: `${this.checkedList.length}件の写真を公開してもよろしいですか？`,
            color: "primary",
            label: "公開する",
          };
        } else if (this.selectedAction === "delete") {
          return {
            title: `${this.checkedList.length}件の写真を削除してもよろしいですか？`,
            color: "red",
            label: "削除",
          };
        } else {
          return {
            title: "",
            color: "",
            label: "",
          };
        }
      })();
      this.isModal = true;
    },
    closeModal() {
      this.isModal = false;
    },
    formatPlace(value) {
      return value ? value : "ー";
    },
    formatShotAt(value) {
      if (!value) return "ー";
      return DateTime.fromISO(value).toFormat("yyyy.MM.dd - HH:mm");
    },
    formatStatus(value) {
      if (!value) return "ー";
      return value === "published" ? "公開済み" : "下書き";
    },
  },
};
</script>

<style>
.thumbnailContainer {
  display: flex;
  align-items: center;
  min-height: 80px;
  margin: 8px 0;
}
</style>
